// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("slider")

function setVh() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

$(document).on("turbolinks:load", () => {

	$(window).on('resize', () => { setVh(); });
	setVh();

	let bgTimeout = setTimeout(() => {$('#video').off('click'); $('#video').hide();}, 20000);

	$('#video').click(function(e){
		e.stopPropagation();
		$('#video').off('click');
		clearTimeout(bgTimeout);
		$('#bgVideo').removeClass('videoFadeOutLater');
		$('.name').removeClass('videoFadeOutLater');
		$('#video').addClass('videoFadeOutNow');
		$('.name').removeClass('videoFadeOutLater');
		setTimeout(() => {$('#video').hide();}, 1500);
	});

	$('#menu-top-right-mobile-toggle').change(function(){
		if($(this).prop('checked') == true){
			$('label').addClass('expanded');
		}
		else{
			$('label').removeClass('expanded');
		}
	});
}); 


