$(document).on("turbolinks:load", () => {

	$('.photo-slider-image').on('load', function() {
		$('#photo-slider').scrollLeft($('#photo-slider')[0].scrollWidth/2 - $(window).width()/2);
	});


	// https://stackoverflow.com/questions/23952491/jquery-mousewheel-scroll-horizontally/36879891#36879891
	$('#photo-slider').on('mousewheel DOMMouseScroll', function(e){
		var delta = Math.max(-1, Math.min(1, (e.originalEvent.wheelDelta || -e.originalEvent.detail)));
		$(this).scrollLeft( $(this).scrollLeft() - ( delta * 10 ) );
		e.preventDefault();
	});

	$('.photo-slider-image').click(function(){
		$('.photo-slider-image-description').not($('.photo-slider-image-description', $(this).parent())).removeClass('expanded')
		$('.photo-slider-image-description', $(this).parent()).toggleClass('expanded')
	});	

	var slidertimer = null
	$('.control-left').hover(
		function(){
			slidertimer = setInterval(function() {
				$('#photo-slider').scrollLeft($('#photo-slider').scrollLeft() - (1));
			}, 7);
		},
		function(){
			clearInterval(slidertimer);
		}
	);

	$('.control-right').hover(
		function(){
			slidertimer = setInterval(function() {
				$('#photo-slider').scrollLeft($('#photo-slider').scrollLeft() + (1));
			}, 7);
		},
		function(){
			clearInterval(slidertimer);
		}
	);
});